.card {
    border-radius: 5px;
    border-width: 1px;
    border-color: gray;
    margin-right: 30px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    width: 350px;

    .colour_circle {
        border-color: lightgray;
        border-width: 1px;
        border-style: solid;
        width: 7mm;
        height: 7mm;
        border-radius: 7mm;
    }
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.card-component {
    max-width: 350px;
    height: 220px;
    border-radius: 10;
    flex: 1;
}

.card-label {
    color: #1E90FF;
    font-weight: 800;
}

.card-description {
    color: #000000;
}

.card-code {
    align-self: flex-start;
    text-align: center;
}

.card-name {
    margin-top: -5px;
    margin-bottom: 30px;
    font-size: 16px;
    text-align: center;
}

.card-agile-text {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 7px;
    border-radius: 5px;
    background-color: #f5f5f5;
}

.card-error {
    color: red;
}

.red {
    background-color: '#FF0000' !important;
    justify-self: 'flex-start';
}

.amber {
    background-color: '#FFBF00' !important;
    justify-self: 'flex-start';
}

.green {
    background-color: '#549C30' !important;
    justify-self: 'flex-start';
}

.white {
    background-color: '#ffffff' !important;
    justify-self: 'flex-start';
}