.bug-rate-container {
    margin-top: 30px;

    .bug-rate-title {
        color: #212529;
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
    }

    .filters-wrapper {
        margin-bottom: 30px;

        .filter-label {
            color: #495057;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .filters-container {
            display: flex;
            align-items: center;

            .reset-button {
                margin-left: 30px;
                color: #212529;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-decoration-line: underline;
                cursor: pointer;
            }
        }
    }

    .overview-container {
        display: flex;
        gap: 30px;
        margin-bottom: 30px;

        .stats-wrapper {
            width: 520px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .stat {
                width: 250px;
                height: 150px;
                padding: 25px;
                border-radius: 10px;
                background: #fafafa;

                h3 {
                    color: #212529;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                h1 {
                    color: #212529;
                    text-align: center;
                    font-size: 36px;
                    font-style: normal;
                    line-height: normal;
                    font-weight: 600;
                }
            }
        }

        .table-wrapper {
            flex: 1;
            overflow: hidden;
            border-radius: 10px;

            .table-heading {
                padding: 16px;
                background: #fafafa;
                text-align: center;

                h3 {
                    color: #212529;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            table {
                width: 100%;

                thead {
                    border-bottom: 1px solid #f0f0f0;
                    border-top: 1px solid #f0f0f0;

                    th {
                        background: #fafafa;
                        padding: 16px;
                        color: #212529;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        border-right: 1px solid #f0f0f0;
                    }
                }

                td {
                    padding: 16px 20px;
                    text-align: center;
                    color: #212529;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    border-left: 1px solid #f0f0f0;
                    border-right: 1px solid #f0f0f0;
                    border-bottom: 1px solid #f0f0f0;
                }
            }
        }
    }

    .breakdown-wrapper {
        margin-bottom: 30px;
    }
}