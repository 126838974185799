.header-style {
    padding: 16px 16px !important;
    border: 1px solid #f0f0f0 !important;
    background-color: #ADB5BD;
    border-top-left-radius: 10px;
    /* Adjust the radius as needed */
    border-top-right-radius: 10px;
    /* Adjust the radius as needed */
}

.col-style {
    border: 1px solid #f0f0f0 !important;
    background-color: #F8F9FA;
}

.background {
    background-color: #F8F9FA;
}

.border-rigth {
    border-right: 1px solid #ADB5BD !important;
}

.border-bottom {
    border-bottom: 1px solid #ADB5BD !important;
}

.col-padding {
    padding: 16px 16px !important;

}

.col-width {
    width: 150px;
}

.margin {
    margin: 0px !important;
}