$color-white: #ffffff;
$color-green: #006233;
$color-silver-sand: #BABBBC;
$color-sky-blue: #20AAE4;
$color-light-sky-blue: #EEF6FF;
$color-dark-blue: #15295A;
$color-scorchsoft-red: #7E0C0C;

// color
$primary-color: $color-sky-blue;
$header-bg-color: $color-scorchsoft-red;
$body-bg-color: $color-white;
$footer-bg-color: $color-light-sky-blue;
$body-title-color: $color-dark-blue;
$body-text-color: $color-dark-blue;
$primary-button-color: $color-sky-blue;

// font
$primary-font-family: 'Verdana';
$font-size-title: 30px;
$font-size-title-md: 25px;