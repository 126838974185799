@import "../../styles/elements";

.login-wrap {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $body-bg-color;
}
.login-info {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-scorchsoft-red;
}
.login-info__text {
    z-index: 1;
    max-width: 544px;
    padding: 40px 32px 48px;
    text-align: center;
    .title {
        margin-top: 16px;
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 600;
        color: $color-white;
    }
    .description {
        font-size: 14px;
        line-height: 1.71;
        color: $color-white;
    }
}
.main-logo {
    display: block;
    max-width: 240px;
    margin: 0 auto;
}
.login-form-box {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 24px 40px 34px;
    z-index: 2;
    background-color: $color-scorchsoft-red;
    .form {
        border-width: 1px;
        border-radius: 24px;
        border-color: black;
        width: 100%;
        padding: 100px 40px 100px 40px;
        background-color: $color-white;
        max-width: 550px;
        margin: 0 auto;
        text-align: center;
    }
    .project-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: normal;
        font-size: 1.8rem;
        line-height: 36px;
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
    }
    .form-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: bold;
        font-size: $font-size-title;
        line-height: 36px;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        padding-bottom: 20px;
    }
    .ant-form-item-label label {
        font-size: 16px;
        line-height: 1.5;
    }
    .login-form-button {
        width: 20vh;
        margin-top: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .login-wrap {
        flex-direction: row;
        height: 100vh;
    }
    .login-form-box {
        .form {
            margin-top: 15.5vh;
        }
        .form-title {
            font-size: $font-size-title;
            margin-bottom: 36px;
        }
    }
    .login-info {
        max-width: 50vw;
        overflow: hidden;
    }
    .login-info__text {
        position: absolute;
        .main-logo {
            max-width: 500px;
        }
        .title {
            margin-top: 24px;
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 1.42;
        }
        .description {
            font-size: 16px;
            line-height: 1.5;
        }
    }
}

@media only screen and (max-width:  400px) { 
    .login-info__text{
        position: absolute;
    }
    .bg-absolute {
        width: 100%;
    }
}