.data-display-item {
    justify-content: center;
    align-items: center;
    // css class name should go inside this wrapper to avoid conflicting class names
}

.container {
    display: flex;
}

.label {
    display: flex;
    width: 40%;
}

.content {
    display: flex;
    width: 60%;
}
